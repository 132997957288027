import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Container } from './style';

const InputPassword = props => {
  const [showPassword, setShowPassword] = useState(props?.type === 'text');
  const iconProps = {
    onClick: () => {
      setShowPassword(!showPassword);
    },
    color: '#02bdbd',
    size: '1.5rem',
    cursor: 'pointer',
    type: showPassword ? 'text' : 'password',
  };

  return (
    <Container>
      <InputGroup>
        <Form.Control
          data-testid="inputPassword"
          {...props}
          type={showPassword ? 'text' : 'password'}
        />
        <InputGroup.Text>
          {showPassword ? (
            <Visibility data-testid="passwordVisible" {...iconProps} />
          ) : (
            <VisibilityOff data-testid="passwordHidden" {...iconProps} />
          )}
        </InputGroup.Text>
      </InputGroup>
    </Container>
  );
};

InputPassword.defaultProps = {
  type: 'password',
};

InputPassword.propTypes = {
  type: PropTypes.string,
};

export default InputPassword;

import { useCallback } from 'react';
import { useStorageConfig } from './useStorageConfig';

export const useFilePath = () => {
  const { data: config } = useStorageConfig();

  const buildImagePath = useCallback(
    (imagePath, assetId) => {
      if (imagePath.includes('https')) {
        return imagePath;
      }
      return `https://${config?.account}.blob.core.windows.net/${
        config?.container
      }/${
        imagePath.includes('/')
          ? imagePath
          : `asset/images/${assetId}/${imagePath}`
      }`;
    },
    [config?.account, config?.container],
  );

  const buildBackgroundPath = useCallback(
    (backPath, displayId) => {
      return `https://${config?.account}.blob.core.windows.net/${config?.container}/display/backgrounds/${displayId}/${backPath}`;
    },
    [config?.account, config?.container],
  );

  const buildDisplayPath = useCallback(
    img => {
      return `https://${config?.account}.blob.core.windows.net/${config?.container}/display/frames/${img}`;
    },
    [config?.account, config?.container],
  );

  const getFilename = useCallback(docPath => {
    if (docPath.includes('https')) {
      return decodeURIComponent(docPath.split('/')[4].split('?')[0]);
    }
    return docPath.includes('/') ? docPath.split('/').at(-1) : docPath;
  }, []);

  const buildDocPath = useCallback(
    (docPath, assetTypeId, sensorTypeId) => {
      if (!docPath) return '';
      if (docPath.includes('https')) {
        return docPath;
      }
      const prefix = assetTypeId ? 'assetType' : 'sensorType';
      return `https://${config?.account}.blob.core.windows.net/${
        config?.container
      }/${
        docPath.includes('/')
          ? docPath
          : `${prefix}/docs/${assetTypeId || sensorTypeId}/${docPath}`
      }`;
    },
    [config?.account, config?.container],
  );

  return {
    buildImagePath,
    buildDocPath,
    getFilename,
    buildBackgroundPath,
    buildDisplayPath,
  };
};

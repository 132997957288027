import PropTypes from 'prop-types';
import React from 'react';
import { Box, Stack } from '@mui/material';
import { FormControl } from 'react-bootstrap';
import { Info } from '@mui/icons-material';

import CustomLabel from '../CustomLabel';
import ErrorFeedbackP from '../ErrorFeedbackP';
import CustomTooltip from '../CustomTooltip';

const CustomFormControl = ({
  id,
  label,
  onChange,
  onBlur,
  touched,
  errors,
  value,
  type,
  info,
  required,
  useTranslate,
  maxLength,
  ...props
}) => {
  return (
    <Box>
      <Stack direction="row" alignItems="center">
        <CustomLabel htmlFor={id} required={required}>
          {label}
        </CustomLabel>
        {info && (
          <CustomTooltip title={info} placement="top">
            <Info sx={{ marginLeft: '10px', marginTop: '8px' }} />
          </CustomTooltip>
        )}
      </Stack>
      <FormControl
        type={type}
        id={id}
        name={id}
        data-testid={id}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        {...props}
      />
      <ErrorFeedbackP
        touched={touched[id]}
        error={errors[id]}
        useTranslate={useTranslate}
        maxLength={maxLength}
      />
    </Box>
  );
};

CustomFormControl.defaultProps = {
  type: 'text',
  info: '',
  required: false,
  useTranslate: false,
  maxLength: 255,
};

CustomFormControl.propTypes = {
  errors: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired,
  maxLength: PropTypes.number,
  type: PropTypes.string,
  info: PropTypes.string,
  required: PropTypes.bool,
  useTranslate: PropTypes.bool,
};

export default CustomFormControl;

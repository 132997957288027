/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React from 'react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Provider, useSelector } from 'react-redux';
import { Store } from '@redux/store';

import Spinner from '../shared/atoms/Spinner';
import * as S from './style';

const dotSVG = `${process.env.REACT_APP_STORAGE_BASE_URL}/dot.svg`;

const MapIconWrapper = props => {
  return (
    <Provider store={Store}>
      <MapIcon {...props} />
    </Provider>
  );
};

const Loading = () => {
  const theme = useSelector(store => store.theme.industry);
  const industries = useSelector(store => store.industriesState);
  return (
    <ThemeProvider theme={{ industry: theme }} industries={{ industries }}>
      <Spinner theme={{ industry: '#ffffff' }} />
    </ThemeProvider>
  );
};

const Fallback = () => {
  return <span>Invalid URL!</span>;
};

const MapIcon = ({ id, src, name, status, dotIcon, coordinates, onClick }) => {
  return (
    <S.MapIcon
      $dotIcon={dotIcon}
      onClick={ev => onClick(ev, { id, coordinates, name, status })}
      $status={status || false}
    >
      <div className={status}>
        <ReactSVG
          id={id}
          src={dotIcon ? dotSVG : src}
          onError={error => {
            if (error) {
              console.error(error);
            }
          }}
          evalScripts="always"
          fallback={Fallback}
          loading={Loading}
          renumerateIRIElements={false}
        />
        <h5>{name}</h5>
      </div>
    </S.MapIcon>
  );
};

MapIcon.defaultProps = {
  onClick: () => {},
  coordinates: [],
  status: 'default',
  dotIcon: false,
  name: '',
};

MapIcon.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  name: PropTypes.string,
  dotIcon: PropTypes.bool,
  coordinates: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string,
  onClick: PropTypes.func,
};

export default MapIconWrapper;
